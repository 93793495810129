.add-project-popup .modal-content {
    background: #f4f4f4;
    padding: 15px;
}
.add-project-popup .modal-header {
    border-bottom: none;
}
.add-project-popup .modal-body {
    display: unset;
}
.add-project-popup .modal-close {
    background: rgba(0, 0, 0, 0.3);
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
}
.add-project-popup .modal-90w {
    max-width: none !important;
    width: 75%;
    /* margin-top: 100px; */
}

.add-project-popup .box {
    background: #fff;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.add-project-popup .box-2 {
    background: #fff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 10px;
}

.add-project-popup .question {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.add-project-popup .question-asterisk {
    color: #8b0032;
}

.add-project-popup .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #8b0032;
    --bs-btn-border-color: #8b0032;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8b0032;
    --bs-btn-hover-border-color: #8b0032;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8b0032;
    --bs-btn-active-border-color: #8b0032;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #8b0032;
    --bs-btn-disabled-border-color: #8b0032;
}

.add-project-popup .btn-outline-primary {
    --bs-btn-color: #8b0032;
    --bs-btn-border-color: #8b0032;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8b0032;
    --bs-btn-hover-border-color: #8b0032;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8b0032;
    --bs-btn-active-border-color: #8b0032;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #8b0032;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #8b0032;
    --bs-gradient: none;
}
.add-project-popup .ml-30 {
    margin-left: 30px;
}

.add-project-popup .mr-30 {
    margin-right: 30px;
}

.add-project-popup input {
    border: 1px solid #dae0e3;
    width: 100%;
    padding: 10px 20px;
    background: #fbfbff;
    border-radius: 10px;
}

.add-project-popup .box-3 {
    border: 1px solid #dae0e3;
    width: 100%;
    height: 330px;
    background: #fbfbff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    padding: 20px;
    overflow: hidden;
}

.add-project-popup input:focus-visible {
    outline: none !important;
}

.add-project-popup .w-ful {
    width: -webkit-fill-available;
}

.add-project-popup .btn-container {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.add-project-popup .text-1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #7e8b95;
}

.add-project-popup .text-2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #869199;
}

.add-project-popup .mr-5 {
    margin-right: 5px;
}

.add-project-popup .quill {
    width: 100%;
    background: #fbfbff;
    height: 100%;
    border: 1px solid #dae0e3;
    border-radius: 10px;
}

.add-project-popup .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
}

.add-project-popup .pr-15 {
    padding-right: 15px !important;
}

.add-project-popup .file-uploaded {
    text-align: center;
    cursor: pointer;
}

.add-project-popup .hide-arrow .splide__arrow {
    display: none;
}

.add-project-popup .add-cover-image {
    height: 170px;
    width: 270px;
    border-radius: 10px;
}
.add-project-popup .add-content-images {
    height: 200px;
    width: 260px;
    border-radius: 10px;
    object-fit: fill !important;
}
.add-project-popup .add-content-videos {
    height: 200px;
    width: 260px;
    border-radius: 10px;
    object-fit: cover !important;
}
.add-project-popup .add-content-images:hover {
    opacity: 0.5;
}
.add-project-popup .add-content-videos:hover {
    opacity: 0.5;
}
.add-project-popup .add-projects-splide {
    width: 430px;
}
.add-project-popup .add-projects-splide .splide__slide {
    display: flex;
    justify-content: center;
}

.add-project-popup .add-projects-splide .splide__pagination {
    bottom: -1.5em !important;
    padding-bottom: 5px;
}

.add-project-popup .add-projects-splide .splide__pagination__page.is-active {
    background: #8b0032;
}

.add-project-popup .ql-editor p span {
    background-color: unset !important;
    color: unset !important;
}

.add-project-popup .delete-icon {
    width: 50px;
    height: 50px;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: white;
}

.add-project-popup .image-container {
    position: relative;
    height: 200px;
}

.add-project-popup .delete-icon {
    display: none;
}

.add-project-popup .image-container:hover .delete-icon {
    display: flex;
}
.add-project-popup .image-container:hover .image-display {
    opacity: 0.5;
}

.add-project-popup .image-container:hover,
.add-project-popup .delete-icon:hover .image-container {
    background-color: rgb(139 0 50 / 50%);
    border-radius: 10px;
    z-index: 1111;
}

.add-project-popup .ql-editor {
    overflow-y: auto;
    height: 263px !important;
}

.add-project-popup .ql-editor::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.add-project-popup .ql-editor::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.add-project-popup .ql-editor::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
}

/* Handle on hover */
.add-project-popup .ql-editor::-webkit-scrollbar-thumb:hover {
    background: darkgray;
}

.project-description {
    word-break: break-all;
}

.add-project-popup .disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
}

@media (max-width: 991px) {
    .modal-body {
        padding: 0px;
    }
    .row {
        margin: 0px;
    }
    .btn-container {
        flex-direction: column;
    }
    .add-project-popup .modal-90w {
        width: auto;
    }
    .pr-15 {
        padding-right: 0px !important;
    }
}

@media (max-width: 768px) {
    .add-project-popup .add-projects-splide {
        width: 350px;
    }
    .add-project-popup .box-3 {
        height: 250px;
    }
    .add-project-popup .add-content-images {
        width: 230px;
    }
    .add-project-popup .add-content-videos {
        width: 230px;
    }
}

@media (max-width: 575px) {
    .add-project-popup {
        padding: 15px !important;
    }
    .add-project-popup .add-content-images {
        width: 145px;
        height: 180px;
    }
    .add-project-popup .add-content-videos {
        width: 145px;
        height: 180px;
    }
    .add-project-popup .add-project-upload-icon {
        height: 70px;
        width: 70px;
    }
    .add-project-popup .box-3 {
        height: 260px;
    }
    .add-project-popup .image-container {
        height: 180px;
    }
}
