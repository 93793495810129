.card-layout {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
    padding: 20px;
}

.card-layout-video {
    /* background-color: rgba(37, 45, 71, 1); */
    overflow: hidden;
}

.dashboard-video {
    width: 100%;
    border-radius: 20px;
}

.zoom-card {
    background-color: rgba(37, 45, 71, 1);
}

#zmmtg-root {
    z-index: 2;
}

#zmmtg-root {
    z-index: 2;
}

.zmwebsdk-makeStyles-root-31 {
    width: 596px !important;
    height: 387px !important;
}

/* #zoom-sdk-video-canvas {
    height: 387px !important;
} */

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
.zoom-end-text {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}
.zoom-img {
    max-width: 200px;
    width: -webkit-fill-available;
}

.zoom-cntr {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.no-record-msg {
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(37, 45, 71, 1);
}

.vimeo-video iframe {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
