.upcoming-session-digitbox-dashboard {
    background-color: rgba(139, 0, 50, 1);
    padding: 5px 10px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 22px;
}
.time-separator-dashboard {
    font-weight: 700;
    font-size: 28px;
    margin-right: -8px;
}

.time-span {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    margin-top: 10px;
}

@media (max-width: 575px) {
    .upcoming-session-digitbox-dashboard {
        font-size: 18px;
        padding: 8px 8px;
    }
    .time-separator-dashboard {
        font-size: 15px;
    }
}

@media (min-width: 576px) and (max-width: 991px) {
    .upcoming-session-digitbox-dashboard {
        font-size: 48px;
        padding: 12px 14px;
    }
    .time-separator-dashboard {
        font-size: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .upcoming-session-digitbox-dashboard {
        font-size: 15px;
        padding: 5px 7px;
    }
    .time-separator-dashboard {
        font-size: 15px;
    }
}
