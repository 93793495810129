.nav-underline .nav-link,
.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
  border-bottom: transparent;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  padding-bottom: 10px;
  padding-right: 5px;
  margin-right: 20px;
  color: #b7b7fe;
}

.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
  border-bottom: #b7b7fe solid;
}

.nav-underline {
  border-bottom: rgb(183, 183, 254, 0.5) 0.5px solid;
}

.white-message {
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  gap: 10px;
  background-color: #eff4fb;
  font-size: 16px;
  font-weight: 400;
  color: #1c2434;
}

.l-container {
  gap: 20px;
  align-items: center;
  justify-content: end;
}
.user-name {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.time {
  font-size: 14px;
  font-weight: 400;
  color: #94a2ab;
}

.r-container {
  gap: 20px;
  align-items: center;
}

.blue-message {
  background-color: #3c50e0;
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.text-row {
  display: flex;
  width: 100%;
  border-radius: 20px;
  border: 1px #e2e8f0 solid;
  background: #fff;
  gap: 10px;
  justify-content: space-between;
  padding: 20px;
}

.text-area {
  width: 80%;
  border-radius: 20px;
  border: 1px #fff solid;
  padding: 10px;
  outline: none !important;
}

.btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 20px;
  background-color: #3c50e0;
}

.send {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: space-evenly;
}

.icn {
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.divder {
  border-right: 2px solid #94a2ab;
}

@media (max-width: 991px) {
  .text-row {
    flex-direction: column;
  }

  .divder {
    border-bottom: 2px solid #94a2ab;
  }

  .send {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.accordion-title {
  font-size: 25px;
  font-weight: 700;
  color: #e1efff;
}
.accordion-header {
  margin-bottom: 2rem;
}

.accordion-body {
  font-size: 12px;
  font-weight: 400;
  color: #dbdbff;
}
.acc-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}


.pagination-div{
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiPagination-root button {
  color: #b7b7fe;
}

.MuiPagination-root button.MuiPaginationItem-previousNext{
  background-color: #374368;
}

.MuiPagination-root button.Mui-selected {
  color: rgba(183, 183, 254, 1) !important;
  background-color: #374368;
}

.MuiPagination-root div {
  color: #b7b7fe;
}
