.mile-stone-card-layout {
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    cursor: pointer;
}

.mile-stone-viewall {
    color: rgba(180, 211, 255, 1);
    font-size: 14px;
    cursor: pointer;
}
.mile-stone-body {
    overflow-y: scroll;
    height: 300px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 0 0;
    display: flex;
    width: 100%;
}

.mile-stone-body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.mile-stone-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.mile-stone-body::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.mile-stone-body::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.milestone-header {
    font-size: 15px;
    font-weight: bolder;
}
.milestone-status {
    font-size: 12px;
    border: 1px solid white;
    border-radius: 60px;
    padding: 5px 15px;
}
.milestone-time {
    font-size: 10px;
}

.mile-stone-date-title {
    color: rgba(180, 211, 255, 1);
    font-size: 16px;
}


.overdue {
    background-color: #c2a44e;
}

.due {
    background-color: #8794ef;
}

.completed{
    background-color: #A32349;
}