.card-layout {
    /* background-color: rgba(37, 45, 71, 1); */
    border-radius: 20px;
    padding: 20px;
}

.card-layout-video {
    /* background-color: rgba(37, 45, 71, 1); */
    border-radius: 20px;
    overflow: hidden;
}

.pb-0 {
    padding-bottom: 0 !important;
}

#zmmtg-root {
    z-index: 2;
}

#zmmtg-root {
    z-index: 2;
}

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 20px;
    height: 100%;
}
.zoom-end-text {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}
.zoom-img {
    max-width: 200px;
    width: -webkit-fill-available;
}

.zoom-cntr {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.no-record-msg {
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(37, 45, 71, 1);
}

.pagination-div {
    text-align: center;
    margin: 20px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPagination-root button {
    color: #b7b7fe;
}

.MuiPagination-root button.MuiPaginationItem-previousNext {
    background-color: #374368;
}

.MuiPagination-root button.Mui-selected {
    color: rgba(183, 183, 254, 1) !important;
    background-color: #374368;
}

.MuiPagination-root div {
    color: #b7b7fe;
}
