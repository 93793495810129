.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.file-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38vh;
}
.notifications-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    width: 100%;
}
