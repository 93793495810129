.previous-session-date {
  font-size: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: rgba(180, 211, 255, 1);
}

.previous-session-eye {
  color: rgba(183, 183, 254, 1);
  cursor: pointer;
}

.previous-session-thumbnail {
  width: -webkit-fill-available;
  height: 150px !important;
  border-radius: 10px;
}

.previous-session-card-title {
  font-size: 14px;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.2) !important;
  padding-left: calc(var(--bs-gutter-x) * 0.2) !important;
}

.chevron {
  background-color: rgba(57, 71, 118, 1);
  border-radius: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  color: white;
}

.chevron:hover {
  color: black;
}

.disabled {
  color: black;
  cursor: none !important;
}

.video-player-button {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  background-color: rgba(139, 0, 50, 1);
  outline: none;
  border: none;
  color: white;
  padding: 8px;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .previous-session-thumbnail {
    height: 100%;
    width: 100%;
  }
}
