.todo-add-button {
    background-color: rgba(60, 80, 224, 1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    cursor: pointer;
    width: 100px;
}
.DatePicker_icon {
    position: absolute;
    top: 79%;
    right: 40px;
    transform: translateY(-50%);
}

.todo-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    max-height: 300px;
    padding: 0 20px 0 0;
    width: 100%;
}

.todo-item {
    border-bottom: 0.8px solid rgba(56, 66, 91, 1);
}

.todo-description {
    word-break: break-all;
    font-size: 12px;
    color: darkgrey;
}
.completed-todo-item {
    color: #a07e59;
    text-decoration: line-through;
}

.todo-date {
    font-size: 13px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.todo-body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.todo-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.todo-body::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.todo-body::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.todo-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.todo-checkbox label {
    position: relative;
    cursor: pointer;
}

.todo-checkbox-label {
    height: 0px;
}

.todo-checkbox .todo-checkbox-label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
    height: 15px;
}

.todo-checkbox input:checked + .todo-checkbox-label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #a07e59;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
}
.todo-checkbox :checked {
    border: 2px solid rgba(255, 148, 148, 1);
}

.todo-checkbox input:checked + .todo-checkbox-label:after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 8px;
    width: 6px;
    height: 14px;
    border: solid #a07e59;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.no-space-text {
    word-break: break-all;
}

.swal2-container {
    overflow-y: scroll !important;
}

.todoConfirmButton,
.todoCancelButton {
    border-radius: 10px !important;
}

.addTodoContainer {
    border-radius: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: start !important;
    align-items: center !important;
}

.todo-swal2-input,
#swal-input2,
#swal-input1 {
    background: #515b7d !important;
    border: none !important;
    padding: 12px !important;
    outline: none !important;
    border-radius: 10px !important;
    color: rgba(210, 229, 255, 1) !important;
    margin-top: 15px !important;
}

#swal-input1::placeholder {
    color: rgba(210, 229, 255, 1) !important;
}

.todoTitle {
    font-weight: 400 !important;
}

.add-todo-modal {
    display: block;
    transform: translate(0px, -50px);
    top: 10%;
    padding: 15px;
}
.add-todo-modal .modal-content {
    background: #f4f4f4;
    padding: 0px;
}
.add-todo-modal .modal-header {
    padding: 10px 15px;
}
.add-todo-modal .add-todo-name {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
}

.add-todo-modal .question {
    font-size: 18px !important;
}

.add-todo-modal .modal-body {
    overflow-y: hidden;
    padding: 15px !important;
}

.add-todo-modal .add-todo-date {
    background: #fff;
    margin-top: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.add-todo-modal .add-todo-form {
    width: 100%;
}

.add-todo-modal .add-todo-btn {
    margin-top: 20px;
}

.todo-list-decription::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.todo-list-decription::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.todo-list-decription::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.todo-list-decription::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.invalid-feedback {
    display: flex !important;
}
