.css-b62m3t-container {
    width: 100%;
    max-width: 250px !important;
}

.css-t3ipsp-control:hover {
    border-color: none !important;
    outline: none !important;
    box-shadow: unset !important;
}
.css-t3ipsp-control:focus {
    border-color: none !important;
    outline: none !important;
    box-shadow: unset !important;
}
.css-1nmdiq5-menu {
    color: black !important;
    font-weight: bold !important;
}
.css-qbdosj-Input {
    font-weight: bold;
}
.css-qbdosj-Input input {
    width: 200px !important;
    height: auto !important;
    border: none !important;
}

.css-166bipr-Input input,
.css-1cfo1cf input,
.css-19bb58m input {
    width: 200px !important;
}
.css-qbdosj-Input input:focus-visible {
    border: none !important;
}

input#react-select-2-input {
    height: 20px !important;
    border: none !important;
}
