.noti-table {
    font-size: 14px;
}
.noti-table td {
    background-color: rgba(55, 67, 104, 1);
    color: white;
}
.noti-table th {
    color: white;
    background-color: rgba(28, 34, 55, 1);
    font-size: larger;
}
.noti-table .read-notification {
    background-color: #97a3e763;
}
.table-row-tr {
    width: 100%;
}

.first-row-td {
    width: 1%;
}

.second-row-td {
    width: 20%;
}

.third-row-td {
    width: 50%;
}

.four-row-td {
    width: 20%;
}

.notification-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
