.go-live-button {
    background-color: rgba(139, 0, 50, 1);
    color: white;
    border-radius: 34px;
    outline: none;
    border: none;
}

.column {
    flex: 1;
    position: relative;
    height: 480px !important;
}
.zmwebsdk-makeStyles-meetingCustomize-12 {
    z-index: 9999 !important;
}
.zmwebsdk-makeStyles-root-223 {
    height: 380px !important;
}
.zmwebsdk-makeStyles-chatCustomize-10,
.zmwebsdk-makeStyles-settingCustomize-11,
.zmwebsdk-makeStyles-participantsCustomize-9 {
    z-index: 1111 !important;
    transform: translate3d(10px, 10px, 10px) !important;
}

.zmwebsdk-makeStyles-root-31,
.zmwebsdk-makeStyles-root-50 {
    height: 480px !important;
    width: 291% !important;
}

.zoom-meeting-join {
    height: 230px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.zmwebsdk-makeStyles-root-63 {
    padding: 10px !important;
}

.zmwebsdk-makeStyles-videoCustomize-8 {
    width: 100%;
}

.zmwebsdk-MuiTabs-fixed {
    display: none !important;
}

.zmwebsdk-makeStyles-leftButtonsContainer-37 :nth-child(2) {
    /* display: none !important; */
}

.zmwebsdk-makeStyles-root-63 :nth-child(3) {
    /* display: none !important; */
}

.zmwebsdk-MuiList-root :nth-child(2),
.zmwebsdk-MuiList-root :nth-child(4),
.zmwebsdk-MuiList-root :nth-child(5),
.zmwebsdk-MuiList-root :nth-child(6),
.zmwebsdk-MuiList-root :nth-child(7),
.zmwebsdk-MuiList-root :nth-child(8) {
    /* display: none !important; */
}

.zmwebsdk-makeStyles-root-30,
.zmwebsdk-makeStyles-root-31 {
    pointer-events: none !important;
}

.zoom-full-screen-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    cursor: pointer;
}
.zoom-card-background {
    position: relative;
}
.zmwebsdk-makeStyles-rootInSharing-34 {
    width: 100% !important;
}

.small-screen-mode .zmwebsdk-makeStyles-inSharing-50 {
    flex-shrink: 1 !important;
}
.small-screen-mode .zmwebsdk-makeStyles-wrap-61.react-draggable,
.small-screen-mode .zmwebsdk-makeStyles-wrap-60.react-draggable {
    width: 1530px !important;
    height: 361px !important;
}
.small-screen-mode .zmwebsdk-makeStyles-canvas-63 {
    width: 100%;
}

.small-screen-mode .zmwebsdk-makeStyles-canvas-62 {
    height: 100% !important;
    width: 100% !important;
}

.small-screen-mode .zmwebsdk-makeStyles-root-216 {
    width: 304px !important;
    height: 171px !important;
    top: 108px !important;
}

.full-screen-mode .zmwebsdk-makeStyles-rootInSharing-34 {
    width: 100% !important;
    height: 95vh !important;
}
.full-screen-mode .zmwebsdk-makeStyles-root-54 {
    height: 90vh !important;
}
.full-screen-mode .zmwebsdk-makeStyles-root-227 {
    /* display: none !important; */
    left: 0px !important;
    width: 240px !important;
    height: 200px !important    ;
}

.full-screen-mode .zmwebsdk-makeStyles-wrap-55 {
    width: 100% !important;
}

.sharing-screen-mode.full-screen-mode .zmwebsdk-makeStyles-wrap-55 {
    width: 200px !important;
}

.sharing-screen-mode .zmwebsdk-makeStyles-root-64 :nth-child(4),
.sharing-screen-mode .zmwebsdk-makeStyles-root-64 :nth-child(6),
.sharing-screen-mode .zmwebsdk-makeStyles-root-64 :nth-child(8),
.sharing-screen-mode .zmwebsdk-makeStyles-root-64 :nth-child(9) {
    display: none !important;
}

.full-screen-mode .zmwebsdk-makeStyles-root-53 {
    height: 700px !important;
}
.full-screen-mode .zmwebsdk-makeStyles-wrap-60,
.full-screen-mode .zmwebsdk-makeStyles-canvas-62 {
    height: 630px !important;
    width: 1130px !important;
}
.full-screen-mode .zmwebsdk-makeStyles-root-221,
.full-screen-mode .zmwebsdk-makeStyles-root-230 {
    left: 0px !important;
    width: 220px !important;
    height: 200px !important;
}
.full-screen-mode .zmwebsdk-makeStyles-inSharing-59 {
    overflow: unset !important;
}
/* === media query for desktop start ===== */

@media (max-width: 1440px) {
    .zmwebsdk-makeStyles-wrap-54 {
        width: 100% !important;
    }
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 480px !important;
        width: 310% !important;
    }
}

@media (max-width: 1200px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 385px !important;
        width: 170% !important;
    }
    .zmwebsdk-makeStyles-wrap-224 {
        width: 100% !important;
    }

    .zmwebsdk-makeStyles-root-223 {
        height: 300px !important;
    }

    /* #zoom-sdk-video-canvas {
        height: 300px !important;
    } */

    .column {
        height: 385px !important;
    }
    .zoom-meeting-join {
        height: 385px !important;
    }
}

@media (max-width: 1024px) {
    .zmwebsdk-makeStyles-root-33 {
        height: 340px !important;
    }
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        width: 215% !important;
    }
    /* .zmwebsdk-makeStyles-wrap-54 {
        width: 100% !important;
    }
    .zmwebsdk-makeStyles-root-219 {
        width: 100% !important;
    } */
    .zmwebsdk-makeStyles-root-53 {
        height: 305px !important;
    }
}
/* ===  media query for desktop end ===== */

/* ===  media query for tablet start ===== */
@media (max-width: 991px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 385px !important;
        width: 370% !important;
    }
    .zmwebsdk-makeStyles-wrap-224 {
        width: 100% !important;
    }

    /* .zmwebsdk-makeStyles-root-219 {
        width: 100% !important;
    }
    .zmwebsdk-makeStyles-wrap-54 {
        width: 100% !important;
    } */

    .zmwebsdk-makeStyles-root-223 {
        height: 400px !important;
        width: 100% !important;
    }

    /* #zoom-sdk-video-canvas {
        height: 400px !important;
    } */

    .column {
        height: 385px !important;
    }
    .zoom-meeting-join {
        height: 385px !important;
    }
    .zmwebsdk-makeStyles-root-54 {
        height: 100% !important;
    }
}
/* ===  media query for tablet end ===== */

/* ===  media query for medium start ===== */
@media (max-width: 767px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 385px !important;
        width: 200% !important;
    }
    .zmwebsdk-makeStyles-wrap-224 {
        width: 100% !important;
    }

    .zmwebsdk-makeStyles-root-223 {
        height: 400px !important;
    }

    #zoom-sdk-video-canvas {
        height: 400px !important;
    }

    .column {
        height: 385px !important;
    }

    .zoom-meeting-join {
        height: 485px !important;
    }
    .zmwebsdk-makeStyles-wrap-55 {
        width: 100%;
    }
    .zmwebsdk-makeStyles-root-143 {
        width: 230px !important;
        transform: translate3d(-30px, -10px, 0px) !important;
    }
    .zmwebsdk-makeStyles-root-100 {
        padding: 0px !important;
        min-width: 200px !important;
    }
    .zmwebsdk-makeStyles-header-102 {
        display: none !important;
    }
    .zmwebsdk-makeStyles-contentContainer-144 > * {
        margin-bottom: 10px !important;
    }
    .zmwebsdk-makeStyles-contentContainer-144 {
        padding: 10px !important;
    }
    .zmwebsdk-makeStyles-wrap-55 {
        width: 100% !important;
    }
    .zmwebsdk-makeStyles-root-218 {
        display: none !important;
    }
    .zmwebsdk-makeStyles-center-220 {
        font-size: 10px !important;
    }
    .small-screen-mode .zmwebsdk-makeStyles-root-216 {
        width: 95px !important;
        height: 100px !important;
        top: 15px !important;
    }
    .zmwebsdk-makeStyles-center-211 {
        font-size: 10px !important;
    }
}
/* ===  media query for medium end ===== */

/* ===  media query for mobile start ===== */
@media (max-width: 425px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 285px !important;
        width: 200% !important;
    }

    .zmwebsdk-makeStyles-wrap-224 {
        width: 100% !important;
    }

    .zmwebsdk-makeStyles-root-223 {
        height: 200px !important;
        width: 100%;
    }

    #zoom-sdk-video-canvas {
        height: 200px !important;
    }

    .column {
        height: 285px !important;
    }
    .zoom-meeting-join {
        height: 285px !important;
    }
    .zmwebsdk-makeStyles-videoCustomize-8 {
        width: 100%;
    }

    .zmwebsdk-makeStyles-root-33 {
        height: 240px !important;
    }
    .zmwebsdk-makeStyles-wrap-54 {
        width: 100% !important;
    }
    .small-screen-mode .zmwebsdk-makeStyles-wrap-61.react-draggable,
    .small-screen-mode .zmwebsdk-makeStyles-wrap-60.react-draggable {
        width: 1000px !important;
    }
}

@media (max-width: 375px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 285px !important;
        width: 170% !important;
    }
    .zmwebsdk-makeStyles-root-208 {
        display: none !important;
    }
    .small-screen-mode .zmwebsdk-makeStyles-wrap-61.react-draggable,
    .small-screen-mode .zmwebsdk-makeStyles-wrap-60.react-draggable {
        width: 600px !important;
    }
}

@media (max-width: 374px) {
    .zmwebsdk-makeStyles-root-31,
    .zmwebsdk-makeStyles-root-50 {
        height: 285px !important;
        width: 150% !important;
    }

    .zmwebsdk-makeStyles-wrap-224 {
        width: 100% !important;
    }

    .zmwebsdk-makeStyles-root-223 {
        height: 200px !important;
        width: 100%;
    }

    #zoom-sdk-video-canvas {
        height: 200px !important;
    }

    .column {
        height: 285px !important;
    }
    .zoom-meeting-join {
        height: 285px !important;
    }
    .zmwebsdk-makeStyles-videoCustomize-8 {
        width: 100%;
    }

    .zmwebsdk-makeStyles-root-33 {
        height: 240px !important;
    }
    .zmwebsdk-makeStyles-wrap-54 {
        width: 100% !important;
    }
    .small-screen-mode .zmwebsdk-makeStyles-wrap-61.react-draggable,
    .small-screen-mode .zmwebsdk-makeStyles-wrap-60.react-draggable {
        width: 500px !important;
    }
}
/* ===  media query for mobile end ===== */
