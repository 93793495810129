.title {
    font-size: 36px;
    font-weight: 700;
}

.hr-d {
    border-color: #b7b7fe;
    margin-bottom: 1.5rem;
}

.gap-20 {
    gap: 20px;
}

.wrap {
    white-space: pre-line;
    overflow-wrap: break-word;
    width: 80%;
}

.congrat-text {
    font-size: 18px;
    font-weight: 500;
}

.date-text {
    font-size: 12px;
    font-weight: 500;
    color: #b4d3ff;
}

.mt-8 {
    margin-top: 4rem;
}

.outer-card-layout {
    padding: 2rem;
}

.certificate-img {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.btn-br {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 5px 20px 5px 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    display: flex;
    align-items: center;
}

.download-certificate {
    border: #dbdbff 1px solid;
    height: 47px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
}

.download-certificate:disabled {
    background-color: #a0a0a0; /* Change the background color for disabled state */
    cursor: not-allowed; /* Change the cursor for disabled state */
    color: #666; /* Change the text color for disabled state */
}
.c-img {
    width: -webkit-fill-available;
}

.width-210 {
    width: 210px;
}

@media (max-width: 991px) {
    .certificate-img {
        padding: 1rem;
    }
}

@media (max-width: 575px) and (min-width: 320px) {
    .fd {
        flex-direction: column;
        align-items: center;
    }
}
