.outer-card-layout {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
    padding: 20px;
}

.inner-card-layout-locked-session {
    background-color: rgba(55, 67, 104, 1);
    border-radius: 10px;
    opacity: 50%;
}

.inner-card-layout-unlocked-session {
    background-color: rgba(55, 67, 104, 1);
    border-radius: 10px;
}

.lock-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.watch-session-btn {
    border-radius: 10px;
    background-color: rgba(139, 0, 50, 1);
    color: white;
    outline: none;
    border: none;
    padding: 6px 8px;
}
.feedback-session-btn {
    border-radius: 10px;
    background-color: #bc9c6c;
    color: white;
    outline: none;
    border: none;
    padding: 6px 8px;
}

.session-masterclass-name {
    color: rgba(255, 255, 255, 1);
    font-weight: 300;
    font-size: 14px;
}

.session-keynote-name {
    font-size: 20px;
    font-weight: 700;
}

.session-lock-subtext {
    font-size: 14px;
    font-weight: 500;
}

.session-lock-date {
    font-size: 18px;
    font-weight: 700;
}

.no-space-text {
    word-break: break-all;
}

.session-cover-img {
    width: 240px;
    height: 150px;
    border-radius: 10px;
}

@media (max-width: 1200px) {
    .session-cover-img {
        width: 300px;
    }
}

@media (max-width: 576px) {
    .lock-overlay {
        width: 200px;
    }
}
