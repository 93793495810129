.add-comments-popup .quill {
    height: auto !important;
    color: black !important;
}

.comment-reply-popup .quill {
    height: auto !important;
}

.add-comments-popup .ql-editor {
    overflow-y: scroll;
    height: 115px;
    padding: 5px 15px;
}

.comment-reply-popup .modal-content {
    background: #f4f4f4;
}

.comment-reply-popup .modal-body {
    padding: 0px !important;
    width: unset !important;
}
.comment-reply-popup .ql-toolbar.ql-snow {
    border: none !important;
}

.comment-reply-popup .modal-header .modal-close {
    background: rgba(0, 0, 0, 0.3);
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
}
.comment-reply-popup .modal-footer {
    justify-content: unset !important;
    background: white;
    border-radius: 10px;
}

.add-comments-popup .ql-editor::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.add-comments-popup .ql-editor::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    border-bottom-right-radius: 50px;
}

/* Handle */
.add-comments-popup .ql-editor::-webkit-scrollbar-thumb {
    background: rgb(144, 145, 149);
    border-radius: 10px;
}

/* Handle on hover */
.add-comments-popup .ql-editor::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.comment-reply-popup .ql-editor {
    overflow-y: scroll;
    height: 200px;
    padding: 5px 15px;
}

.comment-reply-popup .ql-editor::-webkit-scrollbar {
    width: 5px;
}

.comment-reply-popup .ql-container {
    height: unset !important;
}

/* Track */
.comment-reply-popup .ql-editor::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.comment-reply-popup .ql-editor::-webkit-scrollbar-thumb {
    background: rgb(144, 145, 149);
    border-radius: 10px;
}

/* Handle on hover */
.comment-reply-popup .ql-editor::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}
.add-comments-popup .quill .ql-toolbar.ql-snow {
    border: none !important;
}
.add-comments-popup .post-comment-btn {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
}
.comment-reply-popup {
    top: 60%;
    transform: translate(0%, -50%);
}

.comment-reply-popup .modal-body {
    overflow: unset;
}

.project-comment-container .comment p {
    margin: 0px;
}
