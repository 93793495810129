.questions-layout {
    background-color: #374368 !important;
}
.key-note-title {
    font-size: 25px;
    font-weight: bold;
}

h6 {
    font-size: 14px;
}

.mini-course-title {
    font-size: 15px;
    margin: 15px 0px 0px 5px;
}
.question-title {
    font-size: 20px;
    font-weight: bold;
}
.time-title {
    font-size: 11px;
    color: #dbdbff;
    font-weight: 400;
    margin: 0;
}
.clock-icon {
    font-size: 20px;
    color: #94a2ab;
}

.flag-icon {
    font-size: 24px;
    color: #94a2ab;
}
.question-description {
    font-size: 12px;
    line-height: 16px;
    /* text-align: justify; */
    font-weight: 400;
}
.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background-color: rgba(139, 0, 50, 1);
    outline: none;
    border: none;
    color: white;
    padding: 8px;
    transform: translate(-50%, -50%);
}
.mini-course-discussion {
    border-left: 4px solid #ff9494 !important;
}

.wd-35 {
    width: 60px;
    text-align: right;
}

.discussion-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    width: calc(100% - 105px);
}
.discussion-topic-title {
    font-size: 14px;
    width: 70%;
}
.discussion-toggle,
.activity-toggle,
.post-toggle {
    display: flex;
    cursor: pointer;
    gap: 10px;
}

.post-container .display-all-post {
    background-color: #385f97;
    padding: 12px;
    border-radius: 3px;
}

.post-icon {
    display: flex;
    height: 35px;
    margin-right: 10px;
    width: 35px;
}
.discription {
    display: flex;
    flex: 0 0 70%;
    flex-direction: column;
}
.discription p {
    font-size: 12px;
    color: #dbdbff;
    margin-bottom: 0px;
}
.discription h6 {
    color: #b7b7fe;
}

.reply-text {
    font-size: 12px;
    font-weight: 400;
    border-left: #ff9494 4px solid;
    padding: 0px 15px;
}

.w-135 {
    width: 135px;
}

.reply-container {
    margin-left: 40px;
}

.child-container {
    margin-left: 20px;
}

.chat-icon {
    display: flex;
    justify-content: end;
    flex: 0 0 10%;
    height: 30px;
    width: 30px;
    margin-bottom: 15px;
}

.cmt-ctr {
    overflow-y: auto;
    max-height: 500px;
}

.post-container {
    /* padding-right: 15px; */
    overflow: auto;
}

.comments-display {
    justify-content: space-between;
}

.comments-border {
    border-bottom: 0.3px solid #b7b7fe;
    margin-left: 10px;
}

.post-container .comments-display::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.post-container .comments-display::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.post-container .comments-display::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.post-container .comments-display::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.replies-count {
    font-size: 12px;
    font-weight: 600;
    color: #dbdbff;
    position: relative;
    right: 18px;
    top: 4px;
}

.reply-t {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.show-all {
    font-size: 12px;
    font-weight: 500;
}

.error {
    font-size: 12px;
    color: red;
    margin: 5px 0px;
}

.playbook-question-video {
    width: 530px;
    height: 300px;
    border-radius: 20px;
}

@media (max-width: 1060px) and (min-width: 992px) {
    .discription {
        display: flex;
        flex: 0 0 55%;
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .chat-icon {
        width: 30px;
        height: 30px;
        justify-content: start;
    }
    .post-icon {
        width: 30px;
        height: 30px;
    }
    .discussion-topic-title {
        font-size: 12px;
        width: 100%;
    }
    .accr {
        flex-direction: column;
    }
    .add-p {
        justify-content: start !important;
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .chat-icon {
        width: 30px;
        height: 30px;
        justify-content: start;
    }
    .post-icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .playbook-question-video {
        width: 100%;
        height: 250px;
        border-radius: 20px;
    }
    .discription {
        display: flex;
        flex: 0 0 40%;
        flex-direction: column;
    }
}
