.add-modal .modal-content {
  background: #f4f4f4;
  padding: 20px;
}
.add-modal .modal-header {
  border-bottom: none;
}
.add-modal .modal-close {
  background: rgba(0, 0, 0, 0.3);
  padding: 5px;
  cursor: pointer;
  border-radius: 20px;
}
.add-modal .modal-90w {
  max-width: none !important;
  width: 90%;
}

.box {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.transparent .modal-content{
  background: transparent !important;
}

.box-2 {
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 10px;
}

.question {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.question-asterisk {
  color: #8b0032;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8b0032;
  --bs-btn-border-color: #8b0032;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8b0032;
  --bs-btn-hover-border-color: #8b0032;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8b0032;
  --bs-btn-active-border-color: #8b0032;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8b0032;
  --bs-btn-disabled-border-color: #8b0032;
}

.btn-outline-primary {
  --bs-btn-color: #8b0032;
  --bs-btn-border-color: #8b0032;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8b0032;
  --bs-btn-hover-border-color: #8b0032;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8b0032;
  --bs-btn-active-border-color: #8b0032;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #8b0032;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8b0032;
  --bs-gradient: none;
}
.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.form-control-input {
  border: 1px solid #dae0e3;
  width: 100%;
  /* height: 60px; */
  padding: 10px 20px;
  background: #fbfbff;
  border-radius: 10px;
}

.form-control-textarea {
  border: 1px solid #dae0e3;
  width: 100%;
  /* height: 60px; */
  padding: 10px 20px;
  background: #fbfbff;
  border-radius: 10px;
}

.box-3 {
  border: 1px solid #dae0e3;
  width: 100%;
  background: #fbfbff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}

input:focus-visible {
  outline: none !important;
  /* border: 1px solid #dae0e3 !important; */
}

.w-ful {
  width: -webkit-fill-available;
}

.btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.text-1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #7e8b95;
}

.text-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #869199;
}

.mr-5 {
  margin-right: 5px;
}

.quill {
  width: 100%;
  background: #fbfbff;
  height: 300px;
  border: 1px solid #dae0e3;
  border-radius: 10px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none;
}

.pr-15 {
  padding-right: 15px !important;
}

@media (max-width: 991px) {
  .modal-body {
    padding: 0px;
  }
  .row {
    margin: 0px;
  }

  /* input {
    height: 120px;
  } */

  .btn-container {
    flex-direction: column;
  }
  .modal-90w {
    width: auto;
  }
  .pr-15 {
    padding-right: 0px !important;
  }
}
