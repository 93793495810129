.congratulations-text {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #4c5155;
}

.congratulations-message-text {
  font-size: 16;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #4c5155;
}

.keep-learning-button {
  width: 200px;
  border-radius: 50px;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8b0032;
  --bs-btn-border-color: #8b0032;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8b0032;
  --bs-btn-hover-border-color: #8b0032;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8b0032;
  --bs-btn-active-border-color: #8b0032;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8b0032;
  --bs-btn-disabled-border-color: #8b0032;
}
.congratulations-modal .modal-content {
  background-color: #fff;
  margin: 10px;
  border: #e0e7ec 6px solid;
  border-radius: 50px;
}
