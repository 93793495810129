input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* ==== Login content ==== */
.login-content-section {
    background: white;
    min-height: 100vh;
    align-items: center;
    width: 100%;
    border-radius: 30px 0px 0px 30px;
    padding: 10px 35px 0px 35px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.login-language-section {
    position: relative;
    margin-left: auto;
    text-align: right;
}

.login-form {
    border-radius: 29px;
    border: 1px solid #b7b7fe;
    background: #fbfbff;
    padding: 40px;
}
.login-catapult-logo {
    margin-bottom: 30px;
    padding-left: 35px;
    height: auto;
    width: 100%;
}
.login-button {
    color: white;
    background: #8b0032;
    width: 100%;
}
.disable-login-button {
    background: #8b0032;
    width: 100%;
    opacity: 0.7;
    color: white;
    cursor: not-allowed;
    pointer-events: painted;
}
.login-footer {
    margin-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    display: flex;
    gap: 10px;
}
.login-footer span {
    color: #87949c;
}
.login-form-content .login-form-title {
    font-size: 30px;
    font-weight: bolder;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #49535b;
}
.login-form-field label {
    font-weight: bolder;
    margin-right: 45px;
}
select {
    appearance: none;
    padding: 10px 35px 10px 10px;
    border: none;
    background-color: #fff;
    font-size: 16px;
    width: auto;
    cursor: pointer;
    outline: none;
}
.select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.login-form-field .btn:hover {
    background: #8b0032;
    color: white;
}
.login-form-field .btn:focus {
    background: #8b0032;
    color: white;
}
.login-form-field .eye-btn:focus {
    background: none !important;
    color: black !important;
    border: none !important;
}
.login-form-field .eye-btn:active:focus-visible {
    outline: none !important;
    border-color: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: none !important;
    color: none !important;
}
/* select option {
  background-color: #fff;
  color: #49535b;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
} */
.form-control:focus {
    border-color: #86b7fe;
    box-shadow: none !important;
}
.login-form-group {
    padding-top: 11px;
    padding-bottom: 11px;
    height: auto;
}
.resend_btn {
    color: rgb(106, 48, 125);
    background: 0px center;
    border: none;
}
.login-content-form {
    padding: 15px;
    margin-top: 50px;
}
.reset-form {
    margin-top: 20px !important;
}
.form-group .btn:hover {
    background: none;
    color: black;
}
.form-group .btn:focus {
    border: none !important;
}
.login-form-title h2 {
    font-weight: bolder;
    text-align: center;
}
.login-form-title p {
    font-weight: bolder;
    text-align: center;
    font-size: 15px;
}
.login-form-title h3 {
    font-weight: 600;
    text-align: center;
    font-size: 25px;
}
.login-button:disabled {
    color: white;
    background-color: #8b0032;
    opacity: 0.7;
    pointer-events: auto;
    cursor: not-allowed;
}

/* ===== select dropdown ===== */
.select-language {
    padding-right: 40px;
    border: none;
}
.language-dropdown .dropdown-item {
    align-items: center !important;
    line-height: 30px !important;
}
.language-dropdown .dropdown-item img {
    width: 30px;
    height: 30px;
}

/* ===  media query for desktop start ===== */
@media (max-width: 1199px) {
    .login-form-field label {
        margin-right: 40px;
    }
    .login-form-field label {
        margin-right: 30px;
    }
    .login-form-field label {
        margin-right: 20px;
    }
    .login-content-section {
        padding: 10px 20px 0px 20px;
    }
}
/* ===  media query for desktop end ===== */

/* ===  media query for tablet start ===== */
@media (max-width: 991px) {
    .login-content-section {
        border-radius: 30px;
    }
}
/* ===  media query for tablet end ===== */

/* ===  media query for medium start ===== */
@media (max-width: 767px) {
    .login-form-field label {
        margin-right: 20px;
    }
    .login-form {
        padding: 30px;
    }
    .login-catapult-logo {
        padding-left: 27px;
    }
}
/* ===  media query for medium end ===== */

/* ===  media query for mobile start ===== */
@media (max-width: 575px) {
    .login-form-field label {
        margin-right: 10px;
        font-size: 15px;
        width: 100%;
    }
    .login-content-section {
        padding: 20px;
    }
    .login-form {
        padding: 20px;
    }
    .otp-verification-form {
        padding: 20px 5px !important;
    }
    .login-catapult-logo {
        padding-left: 20px;
        margin-bottom: 20px;
    }
    .login-content-form {
        padding: 0px;
    }
    .login-catapult-logo img {
        width: 50%;
    }
    .select-icon img {
        width: 12px;
        height: 12px;
    }
    select {
        font-size: 12px;
        padding: 10px 25px 10px 10px;
    }
    .login-form-content .login-form-title {
        font-size: 25px;
    }
    .select-icon {
        top: 46%;
    }
    .login-language-section {
        max-width: auto;
    }
    .login-footer {
        padding-left: 15px;
    }
    .login-content-section {
        min-height: 85vh;
    }
}
/* ===  media query for mobile end ===== */
