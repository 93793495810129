.nav-underline .nav-link,
.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
    border-bottom: transparent;
    font-size: 14px;
    cursor: pointer;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    padding-bottom: 10px;
    padding-right: 5px;
    margin-right: 20px;
    color: #b7b7fe;
}

.nav-underline .nav-link.active,
.nav-link:focus,
.nav-link:hover {
    border-bottom: #b7b7fe solid;
}

.nav-underline {
    border-bottom: rgb(183, 183, 254, 0.5) 0.5px solid;
}

.color-white {
    color: #fff;
}

.outer-card-layout {
    padding: 5px 25px 5px 33px;
}

.w-50 {
    width: 50%;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.align-center {
    align-items: center;
}

.select-text {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.select-d {
    background-color: #3d4b7a;
    color: #fff;
    border-color: rgb(97, 105, 134, 0.5);
    text-align-last: left;
}
.select-d:focus {
    box-shadow: none;
    outline: none;
    border-color: rgb(97, 105, 134, 0.5);
}

.date-input,
.date-input:focus {
    background-color: #3d4b7a;
    color: #fff;
    border-color: rgb(97, 105, 134, 0.5);
    text-align-last: left;
}

.date-input:focus {
    box-shadow: none;
    outline: none;
    border-color: rgb(97, 105, 134, 0.5);
}

.accordion-container {
    background-color: #3d4b7a;
    padding: 30px;
    border-radius: 10px;
    border-left: 4px #ff9494 solid;
}

.accordion-title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    max-width: 80%;
}
.accordion-btn {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.justify-content-between {
    justify-content: space-between;
}

.accordion-question {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #b7b7fe;
}

.add-post {
    color: #dbdbff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: right;
    margin-top: 5px;
}

.show-all-posts {
    background: #385f97;
    padding: 12px 16px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
}
.justify-end {
    justify-content: end;
}

.acc-message {
    align-items: start;
    margin-top: 2rem;
    gap: 20px;
}

.acc-message-question {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    color: #b7b7fe;
}

.acc-message-content {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.accord-yellow {
    border-color: #f8b805;
}

.add-new-project {
    border: #dbdbff 1px solid;
    height: 47px;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    cursor: pointer;
}

.all-projects-layout .all-project-image {
    height: 150px;
    max-width: 100%;
    width: 170px;
    background: linear-gradient(hsla(0, 0%, 100%, 0), rgba(73, 73, 73, 0), #000);
    position: relative;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
}

.all-projects-layout .all-project-name {
    max-width: 100%;
    width: 170px;
}

.all-projects-layout .projects-cover-image {
    height: 170px;
    max-width: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.all-projects-layout .all-project-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    background: linear-gradient(hsla(0, 0%, 100%, 0), rgba(73, 73, 73, 0), #000);
    border-radius: 10px;
}

.file-uploaded-btn {
    background: #8b0032;
    padding: 10px;
    border-radius: 5px;
    color: white;
    border: 2px solid #8b0032;
}
.all-projects-layout .gap-20 {
    gap: 10px;
}

.image-text-span {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}
.action-image-symbol {
    width: 12px;
    height: 12px;
}
.action-image {
    width: 20px;
    height: 20px;
}
.author-image {
    width: 113px;
    height: 113px;
    border-radius: 50%;
}

.project-author-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 148, 148, 1);
}

.all-project-time {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(219, 219, 255, 1);
}

.all-project-detail {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.justify-space-between {
    justify-content: space-between;
}

.back-button {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 5px 20px 5px 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.btn-br {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 47px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 5px 20px 5px 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    gap: 10px;
    display: flex;
    align-items: center;
}

.show-all-projects-details .project-container {
    padding: 20px;
    border-radius: 10px;
}

.project-title {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.3799999952316284px;
    text-align: left;
}

.project-heading {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
}

.project-description-1 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.img-round {
    height: 20px;
    width: 20px;
    border: 1px solid;
    border-radius: 10px;
}

.share {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}

.more-projects {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
}

.page-change-icon-left {
    background-color: #47588c;
    padding: 10px 8px;
    border-radius: 20px;
}

.page-change-icon-right {
    background-color: #47588c;
    padding: 6px 6px;
    border-radius: 20px;
}

.gap-10 {
    gap: 10px;
}

.brd {
    width: 45%;
    border-bottom: rgba(183, 183, 254, 0.5) 1px solid;
    height: 16px;
}

.comment-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
}

.comment-container {
    border-radius: 10px;
    background-color: #374368;
    padding: 20px 10px 0px 10px;
}

.comment-container-reply {
    border-radius: 10px;
    background-color: #374368;
    padding: 10px 0px 0px 10px;
    margin: 10px 0px 10px 40px;
    border-left: 5px solid #a07e59;
}
.comment-user-name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: #ff9494;
}

.comment-time {
    color: #dbdbff;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
}

.comment {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    color: #dbdbff;
}

.project-comment-container .btn-red {
    height: 30px;
    border-radius: 10px;
    padding: 2px 3px;
    background-color: unset !important;
    border: none !important;
}

.comment-btn-brown {
    border: #a07e59 1px solid;
    height: 30px;
    background-color: #a07e59;
    border-radius: 10px;
    padding: 4px 6px;
    font-size: 12px;
}
.comments-reply-container {
    margin: 20px 0px 10px 40px;
    padding-left: 10px;
    border-left: 5px solid #a07e59;
}
.project-by .btn-brown {
    border: #a07e59 1px solid;
    height: 37px;
    width: 113px;
    background-color: #a07e59;
    border-radius: 10px;
    padding: 5px 6px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
}

.project-comment-show {
    overflow-y: scroll;
    max-height: 300px;
    padding: 0 20px 0 0;
    width: 100%;
}

.project-comment-show::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.project-comment-show::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.project-comment-show::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.project-comment-show::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.height-340 {
    min-height: 340px;
}

.date-filter {
    justify-content: end;
}

.all-projects-layout .margin-lr {
    margin: 0px 1px;
}

.margin-lrb {
    margin: 0px 10px;
}

.title {
    font-size: 36px;
    font-weight: 700;
}

.author {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.author-name {
    font-size: 25px;
    font-weight: 600;
    line-height: 16px;
    word-break: break-all;
}

.author-school {
    font-size: 16px;
    font-weight: 500;
    color: #b7b7fe;
    word-break: break-all;
}

.author-description {
    font-size: 14px;
    font-weight: 400;
    color: #dbdbff;
}

.user-profile-image {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.all-projects-layout .image-info {
    position: relative;
    bottom: 15px;
    padding: 0px 5px 0px 10px;
}

.my-project-btn .edit-button {
    border: rgba(139, 0, 50, 1) 1px solid;
    height: 25px;
    background-color: rgba(139, 0, 50, 1);
    border-radius: 10px;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.project-container .project-description p span {
    background-color: unset !important;
    color: unset !important;
}

.show-projects .back-button {
    padding: 0 10px 0 0;
    gap: 0px;
}
.bg-color {
    background-color: rgba(55, 67, 104, 1);
}

.show-all-projects-details .project-container .show-projects-video-files {
    width: 100%;
    margin-top: 20px;
    height: 250px;
    border-radius: 15px;
    object-fit: cover !important;
}

.show-all-projects-details .project-container .show-projects-img-files {
    width: 100%;
    margin-top: 20px;
    height: 250px;
    border-radius: 15px;
    object-fit: fill !important;
}
.more-paginations {
    width: 16%;
}

.more-paginations .css-78trlr-MuiButtonBase-root-MuiIconButton-root,
.css-1yxmbwk {
    padding: 5px !important;
    margin-top: 12px !important;
}

@media (max-width: 2560px) {
    .all-projects-layout .all-project-image {
        height: 210px !important;
        width: 290px !important;
    }
    .all-projects-layout .projects-cover-image {
        width: 290px !important;
        height: 225px !important;
    }
    .all-projects-layout .all-project-image::after {
        height: 225px !important;
    }
    .outer-card-layout {
        padding: 5px 15px 5px 33px;
    }
    .more-paginations {
        width: 9%;
    }
    .show-all-projects-details .project-container .show-projects-video-files,
    .show-all-projects-details .project-container .show-projects-img-files {
        height: 400px;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .all-projects-layout .all-project-image {
        width: 180px !important;
        height: 135px !important;
    }

    .all-projects-layout .all-project-name {
        width: 180px;
    }
    .all-projects-layout .projects-cover-image {
        height: 150px !important;
    }
    .all-projects-layout .all-project-image::after {
        height: 150px !important;
    }
    .more-paginations {
        width: 15%;
    }
    .show-all-projects-details .project-container .show-projects-video-files,
    .show-all-projects-details .project-container .show-projects-img-files {
        height: 250px;
    }
}

@media (max-width: 1200px) {
    .more-paginations {
        width: 19%;
    }
    .more-paginations ul {
        margin-left: 3px;
    }
    .project-comment-container {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .more-paginations {
        width: 25%;
    }
    .more-paginations ul {
        margin-left: 3px;
    }
    .outer-card-layout {
        padding: 5px 25px 5px 45px;
    }
}
@media (max-width: 1008px) {
    .more-paginations {
        width: 26%;
    }
    .more-paginations ul {
        margin-left: 3px;
    }
}
@media (max-width: 991px) {
    .margin-zero {
        margin: 0px;
    }
    .project-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .outer-card-layout {
        padding: 5px 15px 5px 25px;
    }
    .margin-lrb {
        margin: 0px 0px 10px 0px;
    }
    .back-button {
        width: 100%;
    }
    .height-340 {
        min-height: 100% !important;
    }
    .project-container {
        width: -webkit-fill-available;
    }
    .project-detail {
        margin-bottom: 10px;
    }
    .like {
        display: flex !important;
    }
    .hide-mobile {
        display: none !important;
    }

    .date-filter {
        justify-content: start !important;
    }
    .accordion-title {
        max-width: 100%;
    }

    .accr {
        flex-direction: column;
    }

    .add-post {
        text-align: left;
        margin-top: 20px;
    }

    .all-projects-layout .all-project-image {
        width: 220px !important;
    }
    .all-projects-layout .all-project-name {
        width: 220px !important;
    }

    .show-all-projects-details .project-container .show-projects-files {
        width: 100%;
        margin-top: 20px;
        height: 250px;
        object-fit: fill;
    }
    .more-paginations {
        width: 9%;
    }
}

@media (max-width: 963px) {
    .more-paginations {
        width: 10%;
    }
}

@media (max-width: 873px) {
    .more-paginations {
        width: 11%;
    }
}

@media (max-width: 819px) {
    .more-paginations {
        width: 12%;
    }
}
@media (max-width: 834px) {
    .outer-card-layout {
        padding: 5px 15px 5px 37px;
    }
}

@media (max-width: 767px) {
    .project-header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .all-projects-layout .all-project-image {
        width: 100%;
    }

    .all-projects-layout .all-project-name {
        width: 100%;
    }

    .all-projects-layout .all-project-name {
        width: 100%;
    }
    .more-paginations {
        width: 13%;
    }
}

@media (max-width: 749px) {
    .more-paginations {
        width: 14%;
    }
}

@media (max-width: 709px) {
    .more-paginations {
        width: 15%;
    }
}

@media (max-width: 674px) {
    .more-paginations {
        width: 16%;
    }
}
@media (max-width: 644px) {
    .more-paginations {
        width: 20%;
    }
}
@media (max-width: 575px) {
    .show-all-projects-details .project-container {
        padding: 10px 10px !important;
    }
    .show-all-projects-details .project-container .project-title {
        font-size: 19px;
    }
    .show-all-projects-details .project-container .project-detail {
        margin-top: 10px !important;
    }
    .show-all-projects-details .project-container .project-detail .row {
        width: 100% !important;
    }
    .show-all-projects-details .project-container .show-projects-files {
        width: 100%;
        height: 150px;
    }
    .outer-card-layout {
        padding: 15px;
    }
    .all-projects-layout .all-project-image {
        width: 100% !important;
        height: 180px;
    }

    .all-projects-layout .all-project-name {
        width: 100% !important;
    }
    .all-projects-layout .projects-cover-image {
        height: 200px;
    }
    .all-projects-layout .all-project-image::after {
        height: 200px;
    }

    .author-image {
        height: 80px;
        width: 80px;
    }
    .project-header {
        align-items: center;
        margin-top: 20px;
    }
    .author-name {
        font-size: 15px;
    }

    .more-paginations ul {
        margin-left: 3px;
    }
    .show-all-projects-details .project-container .show-projects-video-files,
    .show-all-projects-details .project-container .show-projects-img-files {
        height: 250px !important;
    }
}

@media (max-width: 531px) {
    .more-paginations {
        width: 25%;
    }
}

@media (max-width: 458px) {
    .more-paginations {
        width: 30%;
    }
}

@media (max-width: 409px) {
    .more-paginations {
        width: 35%;
    }
}

@media (max-width: 375px) {
    .more-paginations {
        width: 40%;
    }
}

@media (max-width: 350px) {
    .more-paginations {
        width: 50%;
    }
}
