.resource-card {
    border-bottom: 0.8px solid rgba(56, 66, 91, 1);
    gap: 15px;
}

.recource-icon {
    display: flex;
    flex: 0 0 10%;
    font-size: 25px;
    cursor: pointer;
}
.resources-name {
    display: flex;
    flex: 0 0 72%;
}

.resources-uploaded {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px dotted #b4d3ff;
    padding: 15px;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
}

.resources-card-body {
    overflow-y: scroll;
    height: 300px;
    flex-direction: column;
    padding: 0 15px 0 0;
    display: flex;
    width: 100%;
}

.resources-card-body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.resources-card-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.resources-card-body::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.resources-card-body::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.resources-uploaded-icon {
    font-size: 24px;
    color: #b7b7fe;
}

.resources-uploaded-text {
    color: #dbdbff;
}

.resources-file-uploaded-popup .modal-header {
    border-bottom: 1px solid rgb(214, 209, 209) !important;
    padding: 15px;
}

.resources-file-uploaded-popup .resources-file-uploaded {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
}

.resources-file-uploaded-popup .box-3 {
    border: 1px solid #dae0e3;
    width: 100%;
    background: #fbfbff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.resources-file-uploaded-popup .modal-body {
    display: flex;
    padding: 20px;
    flex-direction: column;
}

.resources-file-uploaded-popup .modal-content {
    background: #f4f4f4;
    padding: 0px;
}

.resources-file-uploaded-popup .file-size {
    width: 300px;
    height: 200px;
    border-radius: 10px;
}

.resources-file-uploaded-popup .css-b62m3t-container {
    width: 100%;
    min-width: 100% !important;
}

.resources-file-uploaded-popup .css-13cymwt-control {
    border-radius: 10px;
    background-color: #fbfbff !important;
}
.resources-file-uploaded-popup .css-qbdosj-Input input {
    min-width: 100%;
}

.resources-file-uploaded-popup .css-t3ipsp-control,
.resources-file-uploaded-popup .css-t3ipsp-control:hover {
    border-color: unset !important;
    box-shadow: unset !important;
    outline: unset !important;
    border-radius: 10px;
}

.resources-file-uploaded-popup input {
    border: 1px solid #dae0e3;
    padding: 7px;
    background: #fbfbff;
    border-radius: 10px;
    width: 100%;
}
.resources-file-uploaded-popup .selected-file-name {
    border-radius: 10px;
    flex-direction: column;
    width: 100%;
    /* min-width: 500px; */
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.resources-file-uploaded-popup .close-btn {
    background: rgba(0, 0, 0, 0.3);
    padding: 0px;
    cursor: pointer;
    border-radius: 10px;
}

.resources-file-uploaded-popup .selected-file-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.resources-file-uploaded-popup .add-btn {
    height: 50px;
    padding: 10px 15px;
}

.resources-file-uploaded-popup .uploaded-file {
    height: 200px;
    width: 300px;
    border-radius: 10px;
}

.resource-btn-download {
    background: none !important;
    border: none !important;
    color: white !important;
    padding: 0;
    margin: 0;
}
.resources-file-uploaded-popup .modal-header .question {
    font-size: 25px;
}

.resources-file-uploaded-popup .box {
    padding: 13px !important;
    align-items: start;
}

.resources-uploaded {
    margin: 0px 15px 0 4px;
}
.form-not-clickable {
    pointer-events: none;
}
.resources-file-uploaded-popup .modal-close {
    background: rgba(0, 0, 0, 0.3);
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
}

.resources-file-uploaded-popup .css-1jqq78o-placeholder {
    margin-left: 8px !important;
}

.resources-file-uploaded-popup .css-1nmdiq5-menu {
    background: #3636cb !important;
}
.offscreen {
    position: absolute;
    left: -9999px;
}

@media (max-width: 1299px) {
    .resources-uploaded {
        margin: 0px 15px 0 4px;
        border: 1px solid #b4d3ff;
    }
}
@media (max-width: 1024px) {
    .resource-card {
        border-bottom: 0.8px solid rgba(56, 66, 91, 1);
        gap: 8px;
    }
}

@media (max-width: 992px) {
    .resources-uploaded {
        margin: 0px 25px 0 20px;
        border: 1px solid #b4d3ff;
    }

    .resources-name {
        flex: 0 0 82%;
    }
}

@media (max-width: 815px) {
    .resources-uploaded {
        margin: 0px 25px 0 20px;
        border: 1px solid #b4d3ff;
    }

    .resources-name {
        flex: 0 0 78%;
    }
}

@media (max-width: 575px) {
    .resources-file-uploaded-popup input {
        font-size: 13px;
    }
    .resources-file-uploaded-popup .text-1 {
        font-size: 13px;
    }
    .resources-file-uploaded-popup .modal-body {
        padding: 10px;
    }
    .resources-file-uploaded-popup .css-1jqq78o-placeholder {
        font-size: 13px;
    }
    .resources-file-uploaded-popup .modal-header {
        padding: 10px;
    }
    .resources-file-uploaded-popup .modal-header .question {
        font-size: 20px;
    }
    .resources-file-uploaded-popup .css-1dimb5e-singleValue {
        margin-left: 13px;
    }
    .resources-uploaded {
        margin: 0px 20px 0px 0px;
        border: 1px solid #b4d3ff;
    }
    .resources-name {
        flex: 0 0 77%;
    }
    .resources-file-uploaded-popup .uploaded-file {
        height: 200px;
        width: 250px;
        border-radius: 10px;
    }
}

@media (max-width: 556px) {
    .resources-uploaded {
        margin: 0px 20px 0px 4px;
        border: 1px solid #b4d3ff;
    }
    .resources-name {
        flex: 0 0 77%;
    }
}

@media (max-width: 515px) {
    .resources-uploaded {
        margin: 0px 20px 0px 4px;
        border: 1px solid #b4d3ff;
    }
    .resources-name {
        flex: 0 0 74%;
    }
}

@media (max-width: 450px) {
    .resources-uploaded {
        margin: 0px 20px 0px 4px;
        border: 1px solid #b4d3ff;
    }
    .resources-name {
        flex: 0 0 67%;
    }
}

@media (max-width: 335px) {
    .resources-uploaded {
        margin: 0px 15px 0px 0px;
        border: 1px solid #b4d3ff;
    }
    .resources-name {
        flex: 0 0 65%;
    }
}
