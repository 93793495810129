.special-event-card-layout {
    padding: 20px 25px;
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
}

.special-event-image {
    height: 150px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.special-upcoming-event-image {
    /* border-top-left-radius: 20px; */
    /* border-top-right-radius: 20px; */
    width: 400px;
}

.special-upcoming-dummy-event-image {
    height: 250px;
    width: 100%;
    /* margin-left: 150px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.special-event-card-body {
    background-color: rgba(55, 67, 104, 1);
    border: none;
    border-radius: 20px;
}

.special-event-card {
    color: white;
    border: none;
    border-radius: 23px;
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    overflow: hidden;
}

.special-event-card:hover {
    transform: scale(1.02);
}

.special-event-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border: 2px solid transparent;
    transition: border 0.2s;
}

.special-event-card:hover::before {
    animation: borderAnimation 1s ease-out forwards;
    border-radius: 23px;
    border: 3px solid transparent;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
}

.upcoming-event-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.live-image {
    width: 25px;
    height: 25px;
}

.blink-hard {
    animation: blinker 0.9s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes borderAnimation {
    0%,
    100% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    25% {
        border-top-color: #007bff;
    }
    50% {
        border-right-color: #007bff;
    }
    50% {
        border-bottom-color: #007bff;
    }
    0% {
        border-left-color: #007bff;
    }
}

@media (min-width: 992px) and (max-width: 1080px) {
    .special-upcoming-event-image {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    .special-upcoming-dummy-event-image {
        height: 200px;
        width: 300px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}

@media (max-width: 520px) {
    .special-upcoming-event-image {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100% !important;
    }
    .special-upcoming-dummy-event-image {
        height: 150px;
        width: 250px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}
