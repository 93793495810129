.login-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.login-banner {
    flex: 0 0 50%;
}

@media (max-width: 992px) {
    .login-container {
        justify-content: center;
        padding: 50px;
    }
    .login-banner {
        flex: 0 0 100%;
    }
}

@media (max-width: 768px) {
    .login-section-row {
        background-image: url("../../assets/login/back-ground-image.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 100vh;
        display: flex;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 575px) and (min-width: 320px) {
    .login-container {
        padding: 15px;
    }
    .login-section-row {
        background-image: url("../../assets/login/back-ground-image.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 100vh;
        display: flex;
        align-items: center;
        width: 100%;
    }
}
