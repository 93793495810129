.upcoming-session-bg {
    background-color: #000;
    width: 100%;
    height: 400px;
}

.upcoming-session-digitbox {
    background-color: rgba(139, 0, 50, 1);
    padding: 12px 14px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 48px;
}

.upcoming-session-title {
    font-size: 18px;
    font-weight: 700;
}

.time-separator {
    font-weight: 700;
    font-size: 50px;
    margin-right: -8px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .upcoming-session-digitbox {
        font-size: 30px;
        padding: 10px 10px;
    }
    .time-separator {
        font-size: 15px;
    }
}

@media (max-width: 575px) {
    .upcoming-session-digitbox {
        font-size: 25px;
        padding: 15px 13px;
    }
    .time-separator {
        font-size: 15px;
    }
}

@media (max-width: 450px) {
    .upcoming-session-digitbox {
        background-color: rgba(139, 0, 50, 1);
        padding: 7px 7px;
        border-radius: 6px;
        font-weight: 600;
        font-size: 18px;
    }
    .time-separator {
        font-weight: 700;
        font-size: 15px;
        margin-right: -8px;
    }
    .upcoming-session-bg {
        height: 200px;
    }
    .upcoming-session-title {
        font-size: 16px;
    }
}
