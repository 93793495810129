.reports-layout {
    background-color: rgba(37, 45, 71, 1);
    border-radius: 20px;
    padding: 20px;
    height: 100vh;
}

.reports-layout .react-datepicker-popper[data-placement^="top"] {
    z-index: 9999;
}
