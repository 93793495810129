.calendar-container {
    background-color: rgb(55, 67, 104);
}

.rbc-allday-cell {
    display: none;
}

.schedular-section {
    padding: 0 !important;
}
.rbc-calendar {
    padding: 10px;
}
.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 15px !important;
}

.rbc-toolbar .rbc-btn-group {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.rbc-toolbar .left-arrow-btn,
.rbc-toolbar .left-arrow-btn:hover,
.rbc-toolbar .left-arrow-btn:focus,
.rbc-toolbar .right-arrow-btn,
.rbc-toolbar .right-arrow-btn:hover,
.rbc-toolbar .right-arrow-btn:focus {
    font-size: 20px;
    color: white;
    border: none;
    background-color: #374368 !important;
}

.rbc-toolbar .rbc-btn-group .middle-btn {
    background: white;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
}

.rbc-toolbar .rbc-btn-group .view-btn {
    border: none;
    color: white;
    padding: 4px, 16px, 4px, 16px;
    font-size: 15px;
}

.search-btn {
    border-radius: 20px;
    border: none;
    padding: 3px 3px 3px 10px;
    width: 150px;
}
.rbc-timeslot-group {
    min-height: 100px !important;
}
.rbc-day-slot .rbc-time-slot {
    border: none;
}
.rbc-header {
    max-height: 60px;
    padding: 0;
    border-bottom: 1px solid black;
}

.rbc-time-header-content .rbc-header {
    border-left: 1px solid black;
    border-right: 1px solid black;
}
.rbc-off-range-bg {
    background: rgba(28, 34, 55, 1);
}
.rbc-today {
    background-color: #374368;
}
.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.search-input {
    width: 180px;
    height: auto;
    border-radius: 20px;
    padding: 4px 30px 4px 15px;
}

.search-icon {
    position: absolute;
    right: 10px;
    color: black;
    font-size: large;
    cursor: pointer;
}
.rbc-toolbar .rbc-btn-group .view-btn:hover,
.rbc-toolbar .rbc-btn-group .view-btn:focus,
.rbc-toolbar .rbc-btn-group .view-btn:active {
    background-color: #dc2626;
    border-radius: 8px 8px 8px 8px;
}
.rbc-time-content {
    overflow-y: scroll;
    justify-content: space-between;
    display: flex;
    width: 100%;
    border-top: none;
}
.rbc-time-content > * + * > * {
    border-left: 1px solid black;
}

.rbc-time-content::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.rbc-time-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.rbc-time-content::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.rbc-time-content::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}
.rbc-overflowing {
    margin-right: 4px !important;
}

.view-btn.active {
    background-color: #dc2626;
    border-radius: 10px !important;
}

.rbc-toolbar .rbc-toolbar-label {
    padding: 10px 10px 10px 10px !important;
    font-size: 17px;
}

.rbc-time-header.rbc-overflowing .rbc-time-header-content {
    border-left: 0px solid black;
    border-top: 1px solid black;
    border-right: 0px solid black;
}
.rbc-time-view {
    border: none;
    overflow: auto;
}
.rbc-time-header.rbc-overflowing {
    border-right: none;
}
.rbc-time-gutter .rbc-timeslot-group {
    border-bottom: none;
}
.rbc-day-slot .rbc-timeslot-group {
    border-bottom: 1px solid black;
}
.rbc-time-header-cell .rbc-header {
    background-color: #ffe7e7 !important;
    color: #374368;
}

.rbc-time-header-content .rbc-time-header-cell .rbc-header:first-child,
.rbc-time-header-content .rbc-time-header-cell .rbc-header:last-child {
    background-color: #ffe7e7 !important;
    color: #374368 !important;
}

.rbc-day-slot .rbc-events-container {
    margin: 0;
}

.rbc-time-content .rbc-day-slot:last-child {
    border-right: 1px solid black;
}

.rbc-button-link span {
    white-space: pre-line;
    font-size: 18px;
}

.rbc-month-view {
    border: 1px solid black;
}
.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid black;
}
.rbc-header + .rbc-header {
    border-left: 1px solid black;
}

.rbc-events-container .rbc-event .rbc-event-label {
    display: none;
}

.rbc-day-slot .rbc-event-content {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    display: block;
    padding: 2px;
    white-space: nowrap;
    justify-content: center;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    left: 0 !important;
    height: 25px !important;
    width: 100% !important;
    text-align: center;
}
.rbc-event {
    background-color: lightblue;
    color: black !important;
    border-radius: unset !important;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    font-size: 10px !important;
    white-space: unset !important;
}

button.rbc-button-link.rbc-show-more {
    color: white;
    background: none;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #8b0032;
}
.event-popup {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    justify-content: space-between;
    padding: 10px 15px;
}

.meeting-event {
    background-color: rgb(197 247 219);
}

.availability-event {
    background-color: lightblue;
}
.speaker-availability {
    background-color: #fef6e7;
}
.speaker-meeting-event {
    background-color: #f4efff;
}
.rbc-event.rbc-selected {
    background-color: lightblue;
}

.event-info,
.event-popup {
    align-items: center;
}

.event-info p {
    margin-bottom: 0;
}

.modal-body {
    overflow-y: scroll;
    justify-content: space-between;
    display: flex;
    width: 100%;
    border-top: none;
    padding: 15px;
}
.modal-body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}
.add-event-popup .form-group {
    flex: 0 0 50%;
    padding: 0 15px;
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
}
.add-event-popup .form-group input {
    min-height: 40px;
}

/* ===== rc-time-picker ===== */
.rc-time-picker-panel-combobox {
    display: flex;
}

.rc-time-picker-panel-inner {
    top: 59px !important;
}

.rc-time-picker-panel-input-wrap {
    display: none;
}

.rc-time-picker-panel-select {
    max-height: 190px !important;
    width: 66px !important;
}

.rc-time-picker-panel-select li {
    padding: 0 0 0 23px !important;
}

.rc-time-picker-panel-select {
    overflow-y: scroll;
    justify-content: space-between;
    display: flex;
    width: 100%;
    border-top: none;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.rc-time-picker-panel-select::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background: rgba(172, 185, 202, 1);
    border-radius: 10px;
}

/* Handle on hover */
.rc-time-picker-panel-select::-webkit-scrollbar-thumb:hover {
    background: rgba(172, 185, 202, 1);
}

.rc-time-picker-input {
    font-size: 15px !important;
}

.add-event-popup-form .form-control:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.add-event-popup-form .form-control:disabled {
    background: gainsboro;
    border: gainsboro;
    color: black;
    opacity: 0.5;
}

.rbc-time-header-content .rbc-time-header-cell .rbc-header:last-child {
    margin-right: 0px !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    height: auto;
    width: 25em !important;
}
.rbc-month-row {
    overflow: unset !important;
}
div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: x-large;
}
.submit-btn {
    font-size: 13px;
}

.rbc-event {
    padding: 0px;
}
.rbc-label {
    padding: 0px 2px;
    font-size: 11px;
}
.rbc-time-view .rbc-time-gutter {
    text-align: center;
}

.rbc-month-row .rbc-event {
    margin-top: 0 !important;
}
.add-time-modal {
    padding: 10px !important;
    top: 10% !important;
}

.add-time-modal .modal-content {
    background: #f4f4f4 !important;
    padding: 0px !important;
}
.add-time-modal .modal-header {
    padding: 10px 15px !important;
}

.add-time-modal .add-timeZone {
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
}
.add-time-modal .rc-time-picker-input {
    height: 40px !important;
}

.add-time-modal .add-start-time,
.add-time-modal .add-end-time {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

/* ===  media query for desktop start ===== */
@media (max-width: 1199px) {
    .rbc-toolbar {
        gap: 25px;
    }
    .rbc-btn-group + .rbc-btn-group {
        margin-left: 0px;
    }
    .search-input {
        width: 100%;
    }
    .search-container {
        justify-content: center;
        max-width: 50%;
        margin: 0 auto;
    }
    .rbc-day-slot .rbc-event-content {
        font-size: 11px !important;
    }
}
/* ===  media query for desktop end ===== */

/* ===  media query for tablet start ===== */
@media (max-width: 991px) {
    .search-input {
        width: 185px;
    }
    .search-container {
        justify-content: flex-end;
        margin: 0px;
    }
    .rbc-day-slot .rbc-event-content {
        font-size: 10px !important;
    }
    .add-time-modal .modal-body {
        padding: 10px;
    }
}
/* ===  media query for tablet end ===== */

/* ===  media query for medium start ===== */
@media (max-width: 767px) {
    div:where(.swal2-container) div:where(.swal2-popup) {
        font-size: 13px !important;
    }
    .add-time-modal .modal-body {
        padding: 10px;
    }
}
/* ===  media query for medium end ===== */

/* ===  media query for mobile start ===== */
@media (max-width: 575px) {
    .search-input {
        width: 100%;
        font-size: 13px;
    }
    .search-container {
        justify-content: center;
        max-width: 100%;
        margin: 0 auto;
    }
    .rbc-toolbar .rbc-btn-group .middle-btn {
        font-size: 11px;
    }
    .rbc-toolbar .rbc-btn-group .view-btn {
        font-size: 11px;
    }
    .rbc-toolbar .rbc-btn-group {
        gap: 0px;
    }
    .rbc-toolbar .rbc-toolbar-label {
        padding: 0px !important;
        font-size: 11px;
    }
    .rbc-toolbar .left-arrow-btn {
        padding: 0px;
        font-size: 13px;
    }
    .rbc-toolbar .right-arrow-btn {
        padding: 0px;
        font-size: 13px;
    }
    .rbc-month-header {
        width: 600px;
    }
    .rbc-month-view {
        overflow: auto;
    }
    .rbc-month-row {
        width: 600px;
    }
    .rbc-row-segment .rbc-event-content {
        font-size: 12px !important;
    }
    .rbc-time-header.rbc-overflowing {
        width: 600px;
    }
    .rbc-time-content {
        width: 600px;
    }
    .rbc-time-header-content .rbc-time-header-cell .rbc-header:last-child {
        margin-right: 5px !important;
    }
    div:where(.swal2-container) div:where(.swal2-popup) {
        font-size: 10px !important;
    }
    .rbc-button-link span {
        font-size: 15px;
    }
    .rbc-toolbar {
        font-size: 12px !important;
    }
    .search-input {
        border-radius: 10px;
    }
    .rbc-toolbar {
        justify-content: center;
    }
    .btn-sm-group {
        width: 100% !important;
        gap: 40px;
    }
    .add-time-modal .modal-content {
        padding: 0px !important;
    }
    .add-time-modal .modal-header {
        padding: 10px !important;
    }
    .add-time-modal {
        padding: 10px !important;
        top: 15% !important;
    }
    .add-time-modal .modal-body {
        padding: 10px;
    }
    .add-time-modal .add-timeZone {
        padding: 6px;
    }
    .add-time-modal .add-start-time,
    .add-time-modal .add-end-time {
        padding: 8px;
    }
}
/* ===  media query for mobile end ===== */
